
import request from '@/utils/request'

export function getAgencyInformation(params) {
    return request({
        url: '/agent_admin/agency/queryAgency',
        method: 'get',
        params
    })
}

export function getDailyReport(data) {
    return request({
        url: '/agent_admin/dashboard/daily/list',
        method: 'post',
        data
    })
}

export function getAgencyregister(params) {
    return request({
        url: '/agent_admin/agent/register',
        method: 'get',
        params
    })
}

// 下载主播
export function getHostDownload(data) {
    return request({
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob',
        url: '/agent_admin/dashboard/download/daily/list',
        method: 'post',
        data
    })
}

