<template>
  <el-card>
    <div class="title">Hostess Income</div>
    <el-row class="search">
      <el-date-picker v-model="search.date" type="daterange" value-format="yyyy-MM-dd" :picker-options="retainedOptions">
      </el-date-picker>
      <el-input style="width: 180px" v-model="hostessId" placeholder="Please enter hostessId"></el-input>
      <el-button type="primary" @click="query()" :loading="loading">Search</el-button>
      <el-button type="primary" @click="exportDataDownexe" :loading="loading1">
        Download
      </el-button>
    </el-row>
    <el-row>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe border element-loading-text="loading"
        element-loading-spinner="el-icon-loading">
        <el-table-column prop="date" label="Date"></el-table-column>
        <el-table-column prop="agentId" label="Agency ID"></el-table-column>
        <el-table-column prop="hostessId" label="Hostess ID"></el-table-column>
        <el-table-column prop="hostessNickname" label="Name"></el-table-column>
        <el-table-column prop="hostLevel" label="Level"></el-table-column>
        <el-table-column prop="lord" label="Aristocracy"></el-table-column>
        <el-table-column prop="hostessLiveTime" label="Live duration"></el-table-column>
        <el-table-column prop="eHours" label="E-hours"></el-table-column>
        <el-table-column prop="eDays" label="E-days"></el-table-column>

        <el-table-column prop="totalCallCount" label="Total call duration"></el-table-column>
        <el-table-column prop="lastLogin" label="lastLogin"></el-table-column>
        <el-table-column prop="verifyStatus" label="Verify"></el-table-column>

        <el-table-column prop="registerTime" label="registerTime"></el-table-column>
        <el-table-column prop="diamondRevenue" label="Total diamond income">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.diamondRevenue) }}
          </template>
        </el-table-column>
        <el-table-column prop="diamondBalance" label="Diamond balance">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.diamondBalance) }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="user-options">
      <el-pagination :current-page.sync="page" layout="total, prev, pager, next" background
        @current-change="handleCurrentChange" :total="total" class="pagination" :page-size="10"></el-pagination>
    </el-row>
  </el-card>
</template>

<script>
import { getDailyReport, getHostDownload } from '@/api/dashboard.js'
import { datainfor } from '@/utils/formdata.js'
import download from '@/utils/download.js'
export default {
  data() {
    return {
      loading: false,
      loading1: false,
      list: [],
      page: 1,
      total: 0,
      hostessId: '',
      retainedOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 86400000
        }
      },
      search: {
        date: [datainfor(1), datainfor(1)]
      }
    }
  },
  created() {
    this.query()
  },
  methods: {
    // 获取列表
    async query() {
      const { data: res } = await getDailyReport({
        startTime: this.search.date[0],
        endTime: this.search.date[1],
        hostessId: Number(this.hostessId),
        page: this.page
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      this.list = res.data.data
      this.total = res.data.total
    },
    async exportDataDownexe() {
      this.loading1 = true
      const res = await getHostDownload({
        startTime: this.search.date[0],
        endTime: this.search.date[1],
        hostessId: Number(this.hostessId)
      })
      this.loading1 = false
      download(res.data, 'Hostess Income')
    },
    // 分页
    handleCurrentChange() {
      this.query()
    }
  }
}
</script>
